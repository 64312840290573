<template>
	<div id="channel"  v-if="firstFourCategories">
		<div id="categories">
			<b-container class="z-index-2 px-md-9">
				<div class="category" v-for="(category, ckey) in firstFourCategories" :key="ckey">
					<b-row class="pt-lg-5 pb-3 pb-5">
						<b-col cols="auto">
							<router-link :to="{ name: 'category', params: { lang: $route.params.lang, slug: category.category_key } }" class="btn z-index-1  pl-0 btn-category">
								<h3>{{ category.title }}</h3>
								<span><hr></span>
								<hr>
							</router-link>
						</b-col>
					</b-row>
					<Assets :category_id="category.id" types="1,4" />
				</div>
				<div v-if="arcivedCategories">
					<b-row class="pt-lg-5 pb-3 pb-5">
						<b-col cols="auto">
							<h3 class="pr-10">{{ $t('common.archive') }}</h3>
							<hr class="border-secondary border-2 mt-2">
						</b-col>
					</b-row>
					<b-row class="pt-lg-5 pb-3 pb-5">
						<b-col cols="6" lg="4" xl="3" v-for="(category, ckey) in arcivedCategories" :key="ckey" class="mb-3">
							<router-link :to="{ name: 'category', params: { lang: $route.params.lang, slug: category.category_key } }" class="btn z-index-1 text-075 font-roboto d-flex justify-content-center flex-wrap align-content-center w-100 btn-categories">
								{{ category.title }}
							</router-link>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</div>
	</div>
	<Loading v-else />
</template>
<script>
import Assets from '@/components/Assets.vue'
import Loading from '@/components/Loading.vue'
export default {
	name: 'Channel',
	components: {
		Assets,
		Loading
	},
	computed: {
		channel() {
			return this.$store.getters.channel
		},
		firstFourCategories() {
			return _.filter(this.$store.getters.categories, (category, index) => { if (index < 4) { return category } })
		},
		arcivedCategories() {
			let filterCategories = _.filter(this.$store.getters.categories, (category, index) => { if (index >= 4) { return category } })
			return filterCategories.length ? filterCategories : false
		},
	},
	data() {
		return {
			loaded: false
		}
	},
	methods: {

	},
	mounted() {
		this.$i18n.locale = this.$route.params.lang;
	},
}
</script>