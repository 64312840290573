<template>
	<div :id="id+'-assets'" class="pt-4 py-md-5 bg-gradient-white-gray position-relative" @resize.passive="handleResize" v-if="assets && loaded">
		<div class="overflow-hidden hide-scrollbar smooth-scroll" @scroll.passive="handleScroll" ref="assets">
			<b-row :class="$route.name === 'category' && (!category.class && category.class !== 'archive') ? '' : 'flex-md-nowrap'" ref="assetsRow">
				<b-col cols="12" md="4" :lg="$route.name === 'category' && (!category.class && category.class !== 'archive') ? 4 : 3" ref="asset" class="pb-5 mb-5" :key="akey" v-for="(asset, akey) in assets">
					<router-link :to="{ name: 'asset', params: { lang: $route.params.lang, slug: asset.asset_category_key } }" class="btn-asset">
						<div class="embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center">
							<div 	class="embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center bg-cover"
									:class="!asset.thumbnail_url ? 'bg-gray-lightest' : null"
									:style="asset.thumbnail_url ? 'background-image: url('+asset.thumbnail_url+');' : null">
								<font-awesome-icon :icon="getIcon(asset)" class="img-icon" v-if="!asset.thumbnail_url" />
							</div>
							<font-awesome-icon icon="caret-right" class=" position-absolute img-icon z-index-1 " />
						</div>
						<h4 class="pt-3 font-weight-normal font-roboto">{{ getCustomFeildValue(asset.customfields, $i18n.locale === 'sv' ? 'title_sv' : 'title_en' ,asset.title )}}</h4>
						<h6 class="text-075 ellipsis smaller pt-3 font-roboto">{{ getCustomFeildValue(asset.customfields, $i18n.locale === 'sv' ? 'desc_sv' : 'desc_en', asset.description )}}</h6>
						<hr>
					</router-link>
				</b-col>
			</b-row>
		</div>
		<a href="#" class="scroll-icon scroll-left scroll" @click.prevent="arrowScroll('left')" v-if="showScroller && scrolled > 0 && ($route.name !== 'category' || ($route.name === 'category' && category.class &&category.class == 'archive'))"><font-awesome-icon icon="chevron-left" /></a>
		<a href="#" class="scroll-icon scroll-right scroll" @click.prevent="arrowScroll('right')" v-if="showScroller && scrolled < 100 && ($route.name !== 'category' || ($route.name === 'category' && category.class && category.class == 'archive'))"><font-awesome-icon icon="chevron-right" /></a>
	</div>
	<Loading v-else />
</template>
<script>
	import Loading from '@/components/Loading.vue'
	export default {
		components: {
			Loading
		},
		props: {
			category_id: {
				type: Number,
				default: null
			},
			id: {
				type: String,
				default: 'assets'
			},
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			category() {
				return this.$store.getters.category
			},
			categories() {
				return this.$store.getters.categories
			},
			password() {
				return this.$store.getters.password
			},
			type() {
				return this.$store.getters.type
			}
		},
		data() {
			return {
				assets: false,
				loaded: false,
				scrolled: 0,
				showScroller: false
			}
		},
		methods: {
			getCustomFeildValue(data, field, alt) {
				if (_.find(data, { slug: field }) && _.find(data, { slug: field }).value && _.find(data, { slug: field }).value.length) return _.find(data, { slug: field }).value
				return alt ? alt : ''
			},
			getAssets(id) {
				this.loaded = false
				let data = {
					category_id: id,
					types: 1,
					tag: null,
					password: null,
				}
				this.$store.dispatch('assets', data)
				.then(response => {
					this.assets = _.filter(response,( asset) => { if (this.$route.params.slug !== asset.asset_category_key && asset.type === 1) { return asset } })
					var docWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
					this.showScroller = (docWidth < 768 && this.assets.length > 1) || (docWidth < 992 && this.assets.length > 3 )|| this.assets.length > 4
				})
				.finally(() => {
					this.loaded = true
				})
			},
			getIcon(asset) {
				if(asset.type === 1) return 'video'
				if(asset.type === 2) return 'image'
				if(asset.type === 4) return 'podcast'
				if(asset.type === 5) return 'users'
				if(asset.type === 7) return 'audio'
				return 'file'
			},
			handleScroll(e) {
				const containerWidth = e.target.offsetWidth
				const rowWidth = this.$refs.assetsRow.offsetWidth
				const offsetWidth = rowWidth - containerWidth
				const scrollLeft = e.target.scrollLeft
				const assets = this.$refs.asset
				let totalWidth = 0
				assets.forEach(asset => {
					totalWidth = totalWidth + asset.offsetWidth
				})
				const scrollable = totalWidth - containerWidth - offsetWidth
				this.scrolled = Math.round((scrollLeft/scrollable) * 100)
			},
			handleResize() {
				const docWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
				this.showScroller = docWidth < 768 && this.assets.length > 1 || docWidth < 992 && this.assets.length > 2 || this.assets.length > 4
			},
			arrowScroll(direction) {
				const container = this.$refs.assets
				const currentScroll = container.scrollLeft
				const assetWidth = this.$refs.asset[0].offsetWidth
				if(direction === 'left') container.scrollLeft = currentScroll - assetWidth
				else container.scrollLeft = currentScroll + assetWidth
			}
		},
		mounted() {
			this.getAssets(this.category_id)
		},
	}
</script>
