<template>
    <div id="video">
        <b-embed type="iframe" :aspect="getVideoRatio()" :src="src" allow="autoplay; fullscreen"></b-embed>
    </div>
</template>
<script>
    export default {
        computed: {
            asset() {
				return this.$store.getters.asset
			},
			bigSlideRatio() {
				return this.$store.getters.bigSlideRatio
			},
			currentSlide() {
				return this.$store.getters.currentSlide
			},
			stream() {
				return this.$store.getters.stream
			},
			src() {
				if (this.$route.query.secret) return process.env.VUE_APP_PLAYER_SRC + '?slug=' + this.asset.asset_category_key + '&autoplay=1&secret=' + this.$route.query.secret
				if (this.isBroadcasting() && this.$route.query.token) return this.playerUrl() + '&stream=' + this.getStream() + '&autoplay=1&token=' + this.$route.query.token
				if (this.isBroadcasting() && this.$route.query.password) return this.playerUrl() + '&stream=' + this.getStream() + '&autoplay=1&password=' + this.$route.query.password
				if (this.isBroadcasting() && this.$route.query.sso_type && this.$route.query.sso_token) return this.playerUrl() +  '&stream=' + this.getStream() + '&autoplay=1&sso_type=' + this.$route.query.sso_type + '&sso_token=' + this.$route.query.sso_token
				if (this.isBroadcasting() && localStorage.getItem('sso_type') && localStorage.getItem('sso_token')) return this.playerUrl() +  '&stream=' + this.getStream() + '&autoplay=1&sso_type=' + localStorage.getItem('sso_type') + '&sso_token=' + localStorage.getItem('sso_token')
				if (this.isBroadcasting()) return this.playerUrl() + '&stream=' + this.getStream() + '&autoplay=1'
				if (this.$route.query.token) return this.playerUrl() + '&token=' + this.$route.query.token 
				if (this.$route.query.password) return this.playerUrl() + '&password=' + this.$route.query.password
				if (this.$route.query.sso_type && this.$route.query.sso_token) return this.playerUrl() + '&sso_type=' + this.$route.query.sso_type + '&sso_token=' + this.$route.query.sso_token
				if (localStorage.getItem('sso_type') && localStorage.getItem('sso_token')) return this.playerUrl() + '&sso_type=' + localStorage.getItem('sso_type') + '&sso_token=' + localStorage.getItem('sso_token')
				return this.playerUrl()
			}
        },
        methods: {
			playerUrl() {
				return process.env.VUE_APP_PLAYER_SRC + this.asset.asset_category_key + '?format=f' + this.getVideoRatio() + '&fetch=true'
			},
			isBroadcasting(){
				return this.asset.broadcasting && this.asset.broadcasting < 4 && this.asset.status_id === 1
			},
			getSlideRatio() {
				if (!this.currentSlide || !this.bigSlideRatio) return '16by9'
				if (this.bigSlideRatio.aspect === '1by1') return '1by1'
				if (this.bigSlideRatio.aspect === '4by3') return '4by3'
				if (this.bigSlideRatio.aspect === '21by9') return '21by9'
				return '16by9'
			},
			getVideoRatio() {
				if (this.asset.ratio === '1:1') return '1by1'
				if (this.asset.ratio === '4:3') return '4by3'
				if (this.asset.ratio === '21:9') return '21by9'
				return '16by9'
			},
			getCurrentStreamId() {
				if (this.stream) return this.stream
				if (this.$route.query.stream) return this.$route.query.stream
				if (this.asset.streams && this.asset.streams.length) return this.asset.streams[0].id
				return null
			},
            getStream() {
				const streams = this.asset.streams
				const currentStreamId = this.getCurrentStreamId()
				const selectedStream = streams.find(stream => stream['id'] == currentStreamId)
				if (selectedStream) return selectedStream.id
				if (streams && streams.length) return streams[0].id
				return null
			},
			enableIframeCommunication() {
				window.addEventListener('message', event => {
					if (!event.data.currentTime) return
					this.$store.dispatch('setCurrentTime', event.data.currentTime)
				})
			}
		},
        mounted() {
			this.enableIframeCommunication()
            if (this.asset.stream_select || !this.asset.streams) return
			this.$store.dispatch('setStream', this.getStream())
        },
		watch: {
			// 'asset.streams' : function() {
			// 	this.$store.dispatch('setStream', this.getStream())
			// }
		}
    }
</script>
