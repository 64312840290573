var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "category" } },
    [
      _c("div", { staticClass: "bg-not-landing-page" }),
      _vm.loaded && _vm.category
        ? _c("b-container", {}, [
            _c(
              "div",
              { staticClass: "pl-md-8 pb-5" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "d-inline pr-1 font-roboto",
                    attrs: {
                      to: {
                        name: "channel",
                        params: { lang: _vm.$route.params.lang },
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.home")) + " ")]
                ),
                _c("span", { staticClass: "text-075" }, [_vm._v("/")]),
                _vm.parent
                  ? _c(
                      "router-link",
                      {
                        staticClass: "d-inline pr-1 font-roboto",
                        attrs: {
                          to: {
                            name: "category",
                            params: {
                              lang: _vm.$route.params.lang,
                              slug: _vm.parent.category_key,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.parent.title) + " ")]
                    )
                  : _vm._e(),
                _vm.parent
                  ? _c("span", { staticClass: "text-075" }, [_vm._v("/")])
                  : _vm._e(),
                _c("span", { staticClass: "text-075 pl-1 font-roboto" }, [
                  _vm._v(_vm._s(_vm.category.title)),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.loaded && _vm.category
        ? _c(
            "b-container",
            [
              _c("div", { staticClass: "border-hero position-relative" }, [
                _c(
                  "div",
                  {
                    staticClass: "category-hero",
                    style: {
                      "background-image":
                        "url(" + _vm.category.thumbnail_url + ")",
                    },
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "pb-4 d-none d-lg-block",
                        attrs: { cols: "7" },
                      },
                      [_c("h1", [_vm._v(_vm._s(_vm.category.title))])]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "d-none d-lg-block",
                        attrs: { cols: "7" },
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.category.description))])]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-row",
                { staticClass: "d-lg-none pt-5" },
                [
                  _c("b-col", { staticClass: "pb-4", attrs: { cols: "12" } }, [
                    _c("h1", [_vm._v(_vm._s(_vm.category.title))]),
                  ]),
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.category.description))]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.loaded && _vm.category
        ? _c(
            "b-container",
            { staticClass: "px-md-9 pt-7" },
            [
              _c("Assets", {
                attrs: {
                  category_id: _vm.category.id,
                  id: _vm.category.slug,
                  types: "1,4",
                },
              }),
            ],
            1
          )
        : _c("Loading"),
      _vm.loaded && _vm.category ? _c("categories") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }