var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assets && _vm.loaded
    ? _c(
        "div",
        {
          staticClass: "pt-4 py-md-5 bg-gradient-white-gray position-relative",
          attrs: { id: _vm.id + "-assets" },
          on: {
            "&resize": function ($event) {
              return _vm.handleResize.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "assets",
              staticClass: "overflow-hidden hide-scrollbar smooth-scroll",
              on: {
                "&scroll": function ($event) {
                  return _vm.handleScroll.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                {
                  ref: "assetsRow",
                  class:
                    _vm.$route.name === "category" &&
                    !_vm.category.class &&
                    _vm.category.class !== "archive"
                      ? ""
                      : "flex-md-nowrap",
                },
                _vm._l(_vm.assets, function (asset, akey) {
                  return _c(
                    "b-col",
                    {
                      key: akey,
                      ref: "asset",
                      refInFor: true,
                      staticClass: "pb-5 mb-5",
                      attrs: {
                        cols: "12",
                        md: "4",
                        lg:
                          _vm.$route.name === "category" &&
                          !_vm.category.class &&
                          _vm.category.class !== "archive"
                            ? 4
                            : 3,
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn-asset",
                          attrs: {
                            to: {
                              name: "asset",
                              params: {
                                lang: _vm.$route.params.lang,
                                slug: asset.asset_category_key,
                              },
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center bg-cover",
                                  class: !asset.thumbnail_url
                                    ? "bg-gray-lightest"
                                    : null,
                                  style: asset.thumbnail_url
                                    ? "background-image: url(" +
                                      asset.thumbnail_url +
                                      ");"
                                    : null,
                                },
                                [
                                  !asset.thumbnail_url
                                    ? _c("font-awesome-icon", {
                                        staticClass: "img-icon",
                                        attrs: { icon: _vm.getIcon(asset) },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("font-awesome-icon", {
                                staticClass:
                                  " position-absolute img-icon z-index-1 ",
                                attrs: { icon: "caret-right" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "h4",
                            {
                              staticClass:
                                "pt-3 font-weight-normal font-roboto",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCustomFeildValue(
                                    asset.customfields,
                                    _vm.$i18n.locale === "sv"
                                      ? "title_sv"
                                      : "title_en",
                                    asset.title
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-075 ellipsis smaller pt-3 font-roboto",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCustomFeildValue(
                                    asset.customfields,
                                    _vm.$i18n.locale === "sv"
                                      ? "desc_sv"
                                      : "desc_en",
                                    asset.description
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("hr"),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.showScroller &&
          _vm.scrolled > 0 &&
          (_vm.$route.name !== "category" ||
            (_vm.$route.name === "category" &&
              _vm.category.class &&
              _vm.category.class == "archive"))
            ? _c(
                "a",
                {
                  staticClass: "scroll-icon scroll-left scroll",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.arrowScroll("left")
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "chevron-left" } })],
                1
              )
            : _vm._e(),
          _vm.showScroller &&
          _vm.scrolled < 100 &&
          (_vm.$route.name !== "category" ||
            (_vm.$route.name === "category" &&
              _vm.category.class &&
              _vm.category.class == "archive"))
            ? _c(
                "a",
                {
                  staticClass: "scroll-icon scroll-right scroll",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.arrowScroll("right")
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "chevron-right" } })],
                1
              )
            : _vm._e(),
        ]
      )
    : _c("Loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }