var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        { attrs: { id: "channel" } },
        [
          _c("div", { staticClass: "bg-landing-page" }),
          _vm.assetImage && _vm.channel
            ? _c("div", { staticClass: "bg-landing-page-lower" })
            : _vm._e(),
          _vm.assetImage && _vm.channel
            ? _c(
                "b-container",
                { staticClass: "z-index-2" },
                [
                  _c(
                    "div",
                    { staticClass: "p-md-7 position-relative border-hero" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "start-hero",
                          style: {
                            backgroundImage:
                              "url(" + _vm.assetImage.thumbnail_url + ")",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass:
                                "position-absolute fixed-bottom p-4 d-none d-lg-block",
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-8 pl-8",
                                  attrs: { cols: "12", md: "10", lg: "6" },
                                },
                                [
                                  _c("h1", [
                                    _vm._v(_vm._s(_vm.assetImage.title)),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-8 pb-5",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-standard",
                                      attrs: {
                                        href: _vm.getCustomFeildValue(
                                          _vm.assetImage.customfields,
                                          _vm.$i18n.locale === "sv"
                                            ? "hero_link_sv"
                                            : "hero_link_en"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getCustomFeildValue(
                                              _vm.assetImage.customfields,
                                              _vm.$i18n.locale === "sv"
                                                ? "hero_link_text_sv"
                                                : "hero_link_text_en"
                                            )
                                          )
                                      ),
                                      _c("font-awesome-icon", {
                                        staticClass: "pl-3",
                                        attrs: { icon: "caret-right" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "d-lg-none pt-4" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pb-4",
                          attrs: { cols: "12", md: "10", lg: "6" },
                        },
                        [_c("h1", [_vm._v(_vm._s(_vm.assetImage.title))])]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pb-5", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-standard",
                              attrs: {
                                href: _vm.getCustomFeildValue(
                                  _vm.assetImage.customfields,
                                  "hero_link_sv"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getCustomFeildValue(
                                      _vm.assetImage.customfields,
                                      "hero_link_text_sv"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "pl-3",
                                attrs: { icon: "caret-right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.assetImage && _vm.channel && _vm.loaded
            ? _c("Categories")
            : _vm._e(),
        ],
        1
      )
    : _c("Loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }