var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.firstFourCategories
    ? _c("div", { attrs: { id: "channel" } }, [
        _c(
          "div",
          { attrs: { id: "categories" } },
          [
            _c(
              "b-container",
              { staticClass: "z-index-2 px-md-9" },
              [
                _vm._l(_vm.firstFourCategories, function (category, ckey) {
                  return _c(
                    "div",
                    { key: ckey, staticClass: "category" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "pt-lg-5 pb-3 pb-5" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn z-index-1  pl-0 btn-category",
                                  attrs: {
                                    to: {
                                      name: "category",
                                      params: {
                                        lang: _vm.$route.params.lang,
                                        slug: category.category_key,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("h3", [_vm._v(_vm._s(category.title))]),
                                  _c("span", [_c("hr")]),
                                  _c("hr"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("Assets", {
                        attrs: { category_id: category.id, types: "1,4" },
                      }),
                    ],
                    1
                  )
                }),
                _vm.arcivedCategories
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-row",
                          { staticClass: "pt-lg-5 pb-3 pb-5" },
                          [
                            _c("b-col", { attrs: { cols: "auto" } }, [
                              _c("h3", { staticClass: "pr-10" }, [
                                _vm._v(_vm._s(_vm.$t("common.archive"))),
                              ]),
                              _c("hr", {
                                staticClass: "border-secondary border-2 mt-2",
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "pt-lg-5 pb-3 pb-5" },
                          _vm._l(
                            _vm.arcivedCategories,
                            function (category, ckey) {
                              return _c(
                                "b-col",
                                {
                                  key: ckey,
                                  staticClass: "mb-3",
                                  attrs: { cols: "6", lg: "4", xl: "3" },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn z-index-1 text-075 font-roboto d-flex justify-content-center flex-wrap align-content-center w-100 btn-categories",
                                      attrs: {
                                        to: {
                                          name: "category",
                                          params: {
                                            lang: _vm.$route.params.lang,
                                            slug: category.category_key,
                                          },
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(category.title) + " ")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ])
    : _c("Loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }