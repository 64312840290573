var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ondemand" } },
    [
      _c(
        "div",
        { class: _vm.getMediaClasses(), attrs: { id: "media" } },
        [
          _c("b-container", { staticClass: "px-md-9" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("Player"),
                _vm.asset.slideinstances.length ? _c("BigSlide") : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.asset.slideinstances.length ? _c("Controllers") : _vm._e(),
      _vm.asset.slideinstances.length ? _c("Slides") : _vm._e(),
      _c(
        "div",
        { staticClass: "py-5 py-md-8", attrs: { id: "info" } },
        [
          _c(
            "b-container",
            { staticClass: "px-md-9" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pb-7 pb-md-0",
                      attrs: {
                        cols: "12",
                        md:
                          (_vm.asset.chapters && _vm.asset.chapters.length) ||
                          _vm.asset.downloadable === 1 ||
                          _vm.filteredFormats().length
                            ? "8"
                            : "12",
                      },
                    },
                    [
                      _c("h2", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.makeBr(
                              _vm.getCustomFeildValue(
                                _vm.asset.customfieldgroups[0].customfields,
                                _vm.$i18n.locale === "sv"
                                  ? "title_sv"
                                  : "title_en",
                                _vm.asset.title
                              )
                            )
                          ),
                        },
                      }),
                      _c("p", {
                        staticClass: "pt-3 font-roboto smaller",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.makeBr(
                              _vm.getCustomFeildValue(
                                _vm.asset.customfieldgroups[0].customfields,
                                _vm.$i18n.locale === "sv"
                                  ? "desc_sv"
                                  : "desc_en",
                                _vm.asset.description
                              )
                            )
                          ),
                        },
                      }),
                      _c("p", {
                        staticClass: "pt-3 font-roboto smaller",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.makeBr(
                              _vm.getCustomFeildValue(
                                _vm.asset.customfieldgroups[0].customfields,
                                _vm.$i18n.locale === "sv"
                                  ? "disclaim_sv"
                                  : "disclaim_en"
                              )
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  (_vm.asset.chapters && _vm.asset.chapters.length) ||
                  _vm.asset.downloadable === 1 ||
                  _vm.filteredFormats().length
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _vm.asset.downloadable === 1 ||
                          _vm.filteredFormats().length
                            ? _c("Downloads")
                            : _vm._e(),
                          _vm.asset.chapters && _vm.asset.chapters.length
                            ? _c("Chapters")
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.asset.slideinstances.length && _vm.viewSlide
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "slideModal",
                size: "xl",
                show: "",
                "hide-header": "",
                "hide-footer": "",
                centered: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.zoomOutSlide.apply(null, arguments)
                },
              },
            },
            [
              _c("b-img", {
                attrs: { src: _vm.slideUrl(_vm.viewSlide), fluid: "" },
                on: { click: _vm.zoomOutSlide },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }