<template>
	<div id="category">
		<div class="bg-not-landing-page" />
		<b-container v-if="loaded && category" class="">
			<div class="pl-md-8 pb-5">
				<router-link :to="{ name: 'channel', params: { lang: $route.params.lang, } }" class="d-inline pr-1 font-roboto">
					{{ $t('common.home') }}
				</router-link><span class="text-075">/</span>
				<router-link :to="{ name: 'category', params: { lang: $route.params.lang, slug: parent.category_key} }" class="d-inline pr-1 font-roboto" v-if="parent">
					{{ parent.title }}
				</router-link><span class="text-075" v-if="parent">/</span>
				<span class="text-075 pl-1 font-roboto">{{category.title}}</span>
			</div>
		</b-container>
		<b-container v-if="loaded &&  category">
			<div class="border-hero position-relative">
				<div class="category-hero" :style="{'background-image': `url(${category.thumbnail_url})`}">
					<b-col cols="7" class="pb-4 d-none d-lg-block">
						<h1>{{category.title}}</h1>
					</b-col>
					<b-col cols="7" class="d-none d-lg-block">
						<p>{{category.description}}</p>
					</b-col>
				</div>
			</div>
			<b-row class="d-lg-none pt-5">
				<b-col cols="12" class="pb-4">
					<h1>{{category.title}}</h1>
				</b-col>
				<b-col cols="12" class="">
					<p>{{category.description}}</p>
				</b-col>
			</b-row>
		</b-container>
		<b-container v-if="loaded && category" class="px-md-9 pt-7">
			<Assets :category_id="category.id" :id="category.slug" types="1,4" />
		</b-container>
		<Loading v-else/>
		<categories v-if="loaded && category" />
	</div>
</template>
<script>
import Categories from '@/components/Categories.vue'
import Assets from '@/components/Assets.vue'
import Loading from '@/components/Loading.vue'
export default {
	name: 'Category',
	data() {
		return {
			loaded: false,
			category: false,
			parent: false
		}
	},
	components: {
		Assets,
		Loading,
		Categories
	},
	computed: {
		password() {
			return this.$store.getters.password
		},
		channel() {
			return this.$store.getters.channel
		},
	},
	methods: {
		getCategory() {
			this.$store.dispatch('category', { slug: this.$route.params.slug, password: this.password })
			.then(response => {
				this.category = response
				if (response.parent_id !== this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].id) {
					var parent_key = _.find(this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].categories, { id: response.parent_id }).category_key
					this.$store.dispatch('category', { slug: parent_key, password: null })
						.then(response => {
							this.parent = response
						})
				}
				return this.$store.dispatch('categories', { slug: response.category_key, category_id: response.id, password: this.password })
			})
			.then(() => {
				this.loaded = true
			})
			.catch(err => {
				this.handleError(err.response.status)
			})
		},
		handleError() {
			this.$router.push({ name: 'notfound' })
		}
	},
	watch: {
		'$route.name': {
			handler: function (name) {
				if (name === "category") this.getCategory()
			},
			deep: true,
			immediate: true
		}
	}
}
</script>
