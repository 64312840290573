var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: this.bigSlideRatio
        ? "ratio-" + this.bigSlideRatio.aspect
        : "ratio-16by9",
      attrs: { id: "slide" },
    },
    _vm._l(_vm.asset.slideinstances, function (slide, index) {
      return _c("b-img", {
        key: index,
        class:
          _vm.currentSlide && slide.id === _vm.currentSlide.id ? "visible" : "",
        attrs: { center: "", src: _vm.slideUrl(slide) },
        on: {
          click: function ($event) {
            return _vm.zoomSlide(slide)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }