var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion", attrs: { id: "chapters", role: "tablist" } },
    [
      _c(
        "b-card",
        {
          staticClass: "mb-7",
          class: [
            _vm.asset.theme.skin_block_background_color
              ? "bg-" + _vm.asset.theme.skin_block_background_color
              : "bg-light",
            _vm.asset.theme.skin_block_text_color
              ? "text-" + _vm.asset.theme.skin_block_text_color
              : "text-dark",
          ],
          attrs: { "no-body": "" },
        },
        [
          _c("b-card-header", { attrs: { "header-tag": "div", role: "tab" } }, [
            _c("p", { staticClass: "m-0 lead" }, [
              _vm._v(_vm._s(_vm.$t("chapters.title"))),
            ]),
          ]),
          _c(
            "b-collapse",
            { attrs: { id: "chaptersList", role: "tabpanel", visible: "" } },
            [
              _c(
                "b-list-group",
                _vm._l(_vm.asset.chapters, function (chapter, index) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: index,
                      class:
                        _vm.activeChapter() === chapter.id ? "active" : null,
                      on: {
                        click: function ($event) {
                          return _vm.seekToTime(chapter.timecode)
                        },
                      },
                    },
                    [
                      _c(
                        "b-btn",
                        { attrs: { block: "", variant: "link" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "caret-right"] },
                          }),
                          _c("span", {
                            staticClass: "small",
                            domProps: { innerHTML: _vm._s(chapter.label) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }