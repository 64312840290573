<template>
	<section 	id="asset" class="d-flex" v-if="asset && loaded">
		<div class="bg-not-landing-page" />
		<b-container class="px-md-9 pb-8">
			<router-link :to="{ name: 'channel', params: { lang: $route.params.lang } }" class="d-inline pr-1 font-roboto">
				{{ $t('common.home') }}
			</router-link><span class="text-075">/</span>
			<router-link :to="{ name: 'category', params: { lang: $route.params.lang, slug: this.asset.category.category_key } }" class="d-inline px-1 font-roboto" >
				{{asset.category.title}}
			</router-link ><span class="text-075">/</span>
			<span class="text-075 pl-2 font-roboto">{{ getCustomFeildValue(asset.customfieldgroups[0].customfields, this.$i18n.locale === 'sv' ? 'title_sv' : 'title_en', asset.title) }}</span>
		</b-container>
		<OnDemand />
		<b-container class="asset-seclect px-md-9">
			<b-row class="pt-lg-5 pb-3 pb-5">
				<b-col cols="auto">
						<h3>{{$t('common.related')}}</h3>
					<hr class="border-danger">
				</b-col>
			</b-row>
			<Assets :category_id="asset.category.id" :id="asset.category.slug" types="1,4"  class="active"/>
		</b-container>	
	</section>
</template>
<script>
	import OnDemand from '@/components/asset/OnDemand.vue'
	import Assets from '@/components/Assets.vue'	
	export default {
		name: 'Asset',
		data() {
			return {
				loaded: false,
			}
		},
		components: {
			OnDemand,
			Assets
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			}
		},
		methods: {
			getCustomFeildValue(data, field, alt) {
				if (_.find(data, { slug: field }) && _.find(data, { slug: field }).value && _.find(data, { slug: field }).value.length) return _.find(data, { slug: field }).value
				return alt ? alt : ''
			},
			getAsset() {
				this.$store.dispatch('asset', { slug: this.$route.params.slug, password: this.password })
				.then(response => {
					if (this.asset.asset_category_key === response.asset_category_key){
						this.loaded = true
					}
				})
				.catch(err => {
					this.handleError(err.response.status)
				})
			},
		},
		watch: {
			'$route.name': {
				handler: function (name) {
					this.$i18n.locale = this.$route.params.lang;
					if (name === "asset" && this.$route.params.slug) this.getAsset(this.$route.params.slug)
				},
				deep: true,
				immediate: true
			},
		}
	}
</script>
