var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.asset && _vm.loaded
    ? _c(
        "section",
        { staticClass: "d-flex", attrs: { id: "asset" } },
        [
          _c("div", { staticClass: "bg-not-landing-page" }),
          _c(
            "b-container",
            { staticClass: "px-md-9 pb-8" },
            [
              _c(
                "router-link",
                {
                  staticClass: "d-inline pr-1 font-roboto",
                  attrs: {
                    to: {
                      name: "channel",
                      params: { lang: _vm.$route.params.lang },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.home")) + " ")]
              ),
              _c("span", { staticClass: "text-075" }, [_vm._v("/")]),
              _c(
                "router-link",
                {
                  staticClass: "d-inline px-1 font-roboto",
                  attrs: {
                    to: {
                      name: "category",
                      params: {
                        lang: _vm.$route.params.lang,
                        slug: this.asset.category.category_key,
                      },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.asset.category.title) + " ")]
              ),
              _c("span", { staticClass: "text-075" }, [_vm._v("/")]),
              _c("span", { staticClass: "text-075 pl-2 font-roboto" }, [
                _vm._v(
                  _vm._s(
                    _vm.getCustomFeildValue(
                      _vm.asset.customfieldgroups[0].customfields,
                      this.$i18n.locale === "sv" ? "title_sv" : "title_en",
                      _vm.asset.title
                    )
                  )
                ),
              ]),
            ],
            1
          ),
          _c("OnDemand"),
          _c(
            "b-container",
            { staticClass: "asset-seclect px-md-9" },
            [
              _c(
                "b-row",
                { staticClass: "pt-lg-5 pb-3 pb-5" },
                [
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("common.related")))]),
                    _c("hr", { staticClass: "border-danger" }),
                  ]),
                ],
                1
              ),
              _c("Assets", {
                staticClass: "active",
                attrs: {
                  category_id: _vm.asset.category.id,
                  id: _vm.asset.category.slug,
                  types: "1,4",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }