<template>
	<div id="slide" :class="this.bigSlideRatio ? 'ratio-'+this.bigSlideRatio.aspect : 'ratio-16by9'">
		<b-img 	center
				:class="currentSlide && slide.id === currentSlide.id ? 'visible' : ''"
				:src="slideUrl(slide)"
				v-for="(slide, index) in asset.slideinstances"
				:key="index"
				@click="zoomSlide(slide)">
		</b-img>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			viewSize() {
				return this.$store.getters.viewSize
			},
			currentSlide() {
				return this.$store.getters.currentSlide
			},
			bigSlideRatio() {
				return this.$store.getters.bigSlideRatio
			}
		},
		methods: {
			slideUrl(slide) {
				return this.asset.slide_path + '1920/' + slide.path;
			},
			zoomSlide(slide) {
				this.$store.dispatch('viewSlide', slide)
				this.$root.$emit('bv::show::modal', 'slideModal')
			}
		}
	}
</script>
