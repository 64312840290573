var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion", attrs: { id: "downloads", role: "tablist" } },
    [
      _vm.asset.downloadable === 1 ||
      _vm.filteredFormats().length ||
      _vm.filteredAssets().length
        ? _c(
            "b-card",
            {
              staticClass: "mb-7",
              class: _vm.blockClasses(),
              attrs: { "no-body": "" },
            },
            [
              _c(
                "b-card-header",
                { attrs: { "header-tag": "div", role: "tab" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c("b-col", { attrs: { cols: "auto" } }, [
                        _c("p", { staticClass: "m-0 lead" }, [
                          _vm._v(_vm._s(_vm.$t("downloads.title"))),
                        ]),
                      ]),
                      _vm.downloading
                        ? _c(
                            "b-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c("p", { staticClass: "m-0 small" }, [
                                _vm._v(_vm._s(_vm.downloading + "%")),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  attrs: { id: "downloadsList", role: "tabpanel", visible: "" },
                },
                [
                  _vm.asset.downloadable
                    ? _c(
                        "b-list-group",
                        [
                          _vm.asset.downloadable === 1
                            ? _c(
                                "b-list-group-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFromUrl(
                                        _vm.asset.original_format.download_url,
                                        _vm.asset.title +
                                          "." +
                                          _vm.asset.original_format.format
                                            .container
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    { attrs: { block: "", variant: "link" } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "caret-down"] },
                                      }),
                                      _c("span", { staticClass: "small" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.asset.title +
                                              "." +
                                              _vm.asset.original_format.format
                                                .container
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "small text-lowercase" },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.$t("downloads.original")
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filteredFormats().length
                    ? _c(
                        "b-list-group",
                        _vm._l(_vm.filteredFormats(), function (format, index) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFormat(format)
                                },
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { attrs: { block: "", variant: "link" } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "caret-down"] },
                                  }),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.asset.title +
                                          "." +
                                          format.format.container
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      "(" + _vm._s(format.format.name) + ")"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.filteredAssets().length
                    ? _c(
                        "b-list-group",
                        _vm._l(_vm.filteredAssets(), function (asset, index) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(asset)
                                },
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { attrs: { block: "", variant: "link" } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "caret-down"] },
                                  }),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(_vm._s(asset.title)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          asset.type === 1
                                            ? asset.download_formats[0].name
                                            : asset.file_ext
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }