<template>
	<div id="channel" v-if="loaded">
		<div class="bg-landing-page" />
		<div class="bg-landing-page-lower"  v-if="assetImage && channel"/>
		<b-container class="z-index-2" v-if="assetImage && channel">
			<div class="p-md-7 position-relative border-hero">
				<div :style="{backgroundImage:  'url('+assetImage.thumbnail_url+')'}" class="start-hero">
					<b-row class="position-absolute fixed-bottom p-4 d-none d-lg-block">
						<b-col cols="12"  md="10" lg="6" class="pb-8 pl-8">
							<h1>{{assetImage.title}}</h1>
						</b-col>
						<b-col cols="12" class="pl-8 pb-5">
							<b-button class="btn-standard" :href="getCustomFeildValue(assetImage.customfields, $i18n.locale === 'sv' ? 'hero_link_sv' : 'hero_link_en')">
								{{ getCustomFeildValue(assetImage.customfields, $i18n.locale === 'sv' ? 'hero_link_text_sv' : 'hero_link_text_en') }}<font-awesome-icon icon="caret-right" class="pl-3" />
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
			<b-row class="d-lg-none pt-4">
				<b-col cols="12" md="10" lg="6" class="pb-4">
					<h1>{{assetImage.title}}</h1>
				</b-col>
				<b-col cols="12" class="pb-5">
					<b-button class="btn-standard" :href="getCustomFeildValue(assetImage.customfields, 'hero_link_sv')">
						{{ getCustomFeildValue(assetImage.customfields, 'hero_link_text_sv')}}
						<font-awesome-icon icon="caret-right" class="pl-3" />
					</b-button>
				</b-col>
			</b-row>
		</b-container>
		<Categories  v-if="assetImage && channel && loaded"/>
	</div>
	<Loading v-else/>
</template>
<script>
import Categories from '@/components/Categories.vue'
import Loading from '@/components/Loading.vue'
export default {
	name: 'Channel',
	components: {
		Categories,
		Loading
	},
	computed: {
		channel() {
			return this.$store.getters.channel
		},
	},
	data() {
		return {
			loaded: false,
			assetImage: false
		}
	},
	methods: {
		getCustomFeildValue(data, field, alt) {
			if (_.find(data, { slug: field }) && _.find(data, { slug: field }).value.length) return _.find(data, { slug: field }).value
			return alt ? alt : ''
		},
		getAssets(id) {
			this.loaded = false
			let data = {
				category_id: id,
				types: 2,
				tag: null,
				password: null,
			}
			this.$store.dispatch('assets', data)
			.then(response => {
				
				this.assetImage = response[0]
			})
			.finally(() => {
				this.loaded = true
			})
		},
		type(slug) {
			this.$store.dispatch("type", { slug: slug }).then(response => {
				this.$router.push({
					name: response.type,
					params: {lang: 'sv', slug: slug }
				});
			});
		},
		getCategories() {
			this.$store.dispatch('categories', { slug: this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].category_key, password: this.password, category_id: this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].id })
		}
	},
	mounted() {
		if (this.$route.path === '/en' || this.$route.path === '/sv' || this.$route.path === '/en/' || this.$route.path === '/sv/') {
			if (this.$route.path !== '/'+this.$i18n.locale) {
				this.$i18n.locale = this.$route.path === '/en' || this.$route.path === '/en/' ? 'en' : 'sv';
				this.getAssets(this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].id) 
			} else {
				this.getAssets(this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].id) 
			}
		} else if (this.$route.path !== '/' && this.channel.categories[0].categories[this.$i18n.locale === 'en' ? 1 : 0].category_key !== this.$route.params.lang){
			this.type(this.$route.params.lang)
		} else {
			this.$router.push({
				name:  'channel',
				params: { lang: 'sv' }
			});
		}
		this.getCategories()
	},
}
</script>